<template>
    <div class='organicAcid'>
        <div class="userHead">
            <div class="logo">
                <div class="headImg">
                    <img class="img" :src="headUrl" alt="">
                </div>
            </div>
            <div class="basicData">
                <div class="userinfo" v-for="(item, index) in userData" :key="index">
                    <div class="infoItem" v-for="(el, i) in item.data" :key="i">
                        <div class="label">
                            <div class="tipIcon"></div>
                            <div>{{ el.label }}</div>
                        </div>
                        <div>{{ userInfoData[el.value] }}</div>
                    </div>
                </div>
            </div>
            <div class="paging">
                <div class="pagingImg">
                    <img class="img" :src="pagUrl" alt="">
                </div>
            </div>
        </div>
        <!-- 第二页 -->
        <div class="analysis">
            <div style="height: 5px;"></div>
            <div class="title">{{ reportData.title }}</div>
            <div class="content">
                <div class="subtitle">荷尔蒙健康分析</div>
                <div class="info">
                    <div class="contImg">
                        <img class="img" :src="reportData.contentUrl" alt="" mode="widthFix">
                    </div>
                </div>
            </div>
            <div class="leptinTip">
                <img class="img" :src="leptin" alt="">
            </div>
        </div>
        <!-- 第三页 -->
        <div class="analysis">
            <div style="height: 5px"></div>
            <div class="title">{{ reportData.title }}</div>
            <div class="content" v-for="(item, index) in reportData.content2" :key="index">
                <div v-if="item.tableData.length > 0">
                    <div class="subtitle">{{ item.subTitle }}</div>
                    <div class="info">
                        <div class="tableMod">
                            <div class="table">
                                <div class="th">
                                    <div class="tr tr1">不平衡指标</div>
                                    <div class="tr tr2">不平衡程度</div>
                                </div>
                                <div class="tabtd" v-for="(val, y) in item.tableData" :key="y"
                                    :style="{ 'color': val.fontColor }">
                                    <div class="left tr">
                                        <div class="translate" :style="{ 'color': val.fontColor }">{{ val.Ex3 }}</div>
                                        <div class="translate">{{ val.EnTitleName }}</div>
                                    </div>
                                    <div class="right tr">
                                        <div class="degree"
                                            :style="{ 'backgroundImage': `linear-gradient(to right, ${val.color1}, ${val.color2})`, 'width': val.Ex2 }">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="leptinTip">
                <img class="img" :src="leptin" alt="">
            </div>
        </div>
        <!-- 第四页 -->
        <div class="analysis">
            <div style="height: 5px"></div>
            <div class="title">{{ reportData.title }}</div>
            <div class="content">
                <div class="subtitle">{{ reportData.content3.subTitle }}</div>
                <div class="info">
                    <div class="tableMod">
                        <div class="table">
                            <div class="th">
                                <div class="tr tr1">不平衡指标</div>
                                <div class="tr tr2">不平衡程度</div>
                            </div>
                            <div class="tabtd" v-for="(val, y) in reportData.content3.tableData" :key="y"
                                :style="{ 'color': val.fontColor }">
                                <div class="left tr">
                                    <div :style="{ 'color': val.fontColor, 'text-align': 'center' }">
                                        <div class="translate">{{ val.Ex3 }}</div>
                                        <div class="translate">{{ val.EnTitleName }}</div>
                                    </div>
                                </div>
                                <div class="right tr">
                                    {{ val.CoreContent }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="leptinTip">
                <img class="img" :src="leptin" alt="">
            </div>
        </div>
        <!-- 第五页 -->
        <div class="analysis">
            <div style="height: 5px"></div>
            <div class="title">{{ reportData.title }}</div>
            <div class="content">
                <div class="subtitle">{{ reportData.content4.subTitle }}</div>
                <div class="info">
                    <div class="tableMod">
                        <div class="table">
                            <div class="th">
                                <div class="tr tr1">不平衡指标</div>
                                <div class="tr tr2">不平衡程度</div>
                            </div>
                            <div class="tabtd" v-for="(val, y) in reportData.content4.tableData" :key="y"
                                :style="{ 'color': val.fontColor }">
                                <div class="left tr">
                                    <div :style="{ 'color': val.fontColor, 'text-align': 'center' }">
                                        <div class="translate">{{ val.Ex3 }}</div>
                                        <div class="translate">{{ val.EnTitleName }}</div>
                                    </div>
                                </div>
                                <div class="right tr">
                                    {{ val.CoreContent }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="explainTips">
                <img class="img" :src="explainTips" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { getCoreReport } from '@/api/index'
import { sortArr } from '@/util/validate'
// import { unique } from '@/util/validate'

export default {
  components: {

  },
  data () {
    return {
      headUrl: '',
      pagUrl: `${this.$imgDomain}/www/HealthingCore/paging.png`,
      leptin: `${this.$imgDomain}/www/HealthingCore/leptin.png`,
      explainTips: `${this.$imgDomain}/www/HealthingCore/explainTips.png`,
      userInfoData: {},
      userData: [
        {
          data: [
            {
              label: '姓名：',
              value: 'Ex1'
            },
            {
              label: '性别：',
              value: 'Ex3'
            },
            {
              label: '出生年月：',
              value: 'Ex4'
            }
          ]
        },
        {
          data: [
            {
              label: '注册日期：',
              value: 'CreateTime'
            },
            {
              label: '登记编号：',
              value: 'DataValue'
            }
          ]
        }
      ],
      reportData: {
        title: '',
        contentUrl: '',
        content2: [
          {
            subTitle: '',
            tableData: []
          },
          {
            subTitle: '',
            tableData: []
          }
        ],
        content3: {
          subTitle: '参考值说明',
          tableData: []
        },
        content4: {
          subTitle: '参考值说明',
          tableData: []
        },
        content5: {
          subTitle: '参考值说明',
          tableData: []
        }
      }
    }
  },
  props: {

  },
  created () {
    this.getReportData()
  },
  mounted () {

  },
  methods: {
    /*
                           6: "女性荷尔蒙健康分析"
                           7: "睡眠平衡健康分析"
                           8: "雌激素健康分析"
                           15:"男性荷尔蒙健康分析"
                       */
    getReportData () {
      const formData = new FormData()
      const autoid = this.$query('autoid')
      const coretype = this.$query('coretype')
      console.log(coretype, 'coretype')
      formData.append('action', 'GetUserListCoreExpands')
      formData.append('autoid', autoid)
      formData.append('coretype', coretype)
      getCoreReport(formData).then(res => {
        const { result } = res
        if (result) {
          // console.log(result,"result")
          this.userInfoData = result.userExpands
          // console.log(this.userInfoData,"this.userInfoData")
          // const data = result.coreReportInfo
          const data = sortArr(result.coreReportInfo, 'MainId')
          console.log(data, 'data')
          data.forEach(el => {
            el.forEach(item => {
              console.log(item, 'item')
              if (item.Ex2) {
                item.porp = item.Ex2.split('%')[0]
                console.log(item.porp, 'porp')
              } else {
                item.porp = 0
              }
              if (item.porp >= 80) {
                item.color1 = '#FAF0B1'
                item.color2 = '#F5E062'
                item.fontColor = '#D89F15'
              } else {
                item.color1 = '#B7CDA1'
                item.color2 = '#6E9B42'
              }
            })

            if (el[0].MainId.indexOf('分析') !== -1) {
              this.reportData.content2[0].tableData = el
            } else {
              this.reportData.content2[1].tableData = el
            }
          })
          // 解释说明
          console.log(result.coreReportInfo)
          this.reportData.content3.tableData = result.coreReportInfo.splice(0, 4)
          // console.log(this.reportData.content3,"+++")
          this.reportData.content4.tableData = result.coreReportInfo.splice(0, 6)
          // 标题/图片处理
          this.handleTypeData(coretype)
        }
      })
    },
    handleTypeData (type, data) {
      // console.log(type,data,"type")
      if (type === '6') {
        // 荷尔蒙健康分析
        console.log('666666')
        this.reportData.title = '男性荷尔蒙健康分析'
        this.headUrl = `${this.$imgDomain}/www/HealthingCore/hormone1.png`
        this.reportData.contentUrl = `${this.$imgDomain}/www/HealthingCore/hormone2.png`
        this.reportData.content2[0].subTitle = '男性荷尔蒙健康分析'
        this.reportData.content2[1].subTitle = '荷尔蒙健康分析'
      } else if (type === '7') {
        // 睡眠平衡健康分析
        this.headUrl = `${this.$imgDomain}/www/HealthingCore/sleep1.png`
        this.reportData.contentUrl = `${this.$imgDomain}/www/HealthingCore/sleep2.png`
        this.reportData.title = '睡眠平衡健康分析'
        this.reportData.content2[0].subTitle = '睡眠平衡健康分析'
        this.reportData.content2[1].subTitle = '肾上腺压力分析'
      } else if (type === '8') {
        // 雌激素健康分析
        this.headUrl = `${this.$imgDomain}/www/HealthingCore/estrogen1.png`
        this.reportData.contentUrl = `${this.$imgDomain}/www/HealthingCore/estrogen2.png`
        this.reportData.title = '雌激素健康分析'
        this.reportData.content2[0].subTitle = '雌激素健康分析'
      } else if (type === '15') {
        this.reportData.title = '男性荷尔蒙健康分析'
        this.headUrl = `${this.$imgDomain}/www/HealthingCore/manhormone.png`
        this.reportData.contentUrl = `${this.$imgDomain}/www/HealthingCore/manhormonelist.png`
        this.reportData.content2[0].subTitle = '男性荷尔蒙健康分析'
        this.reportData.content2[1].subTitle = '荷尔蒙健康分析'
      }
    }
  },
  watch: {

  },
  computed: {

  }
}
</script>
<style lang='scss' scoped>
.organicAcid {
    width: 592.28px;
    padding: 0 10px;
    .userHead {
        height: 860px;
        position: relative;

        .logo {
            display: flex;
            justify-content: center;

            .headImg {
                width: 90%;
                height: 80%;
            }
        }

        .basicData {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .userinfo {
                border-bottom: 1px solid #ccc;
                display: flex;
                justify-content: space-around;
                width: 90%;
                font-size: 14px;

                .infoItem {
                    display: flex;
                    align-items: center;
                    padding-bottom: 5px;
                    margin-top: 20px;

                    .label {
                        display: flex;
                        align-items: baseline;

                        .tipIcon {
                            width: 10px;
                            height: 10px;
                            background-color: #595757;
                            margin-right: 5px;
                        }
                    }

                }
            }
        }

        .paging {
            display: flex;
            justify-content: center;
            position: absolute;
            bottom: 20px;

            .pagingImg {
                width: 20%;
            }
        }
    }

    .analysis {
        margin-top: 60px;
        height: 860px;
        position: relative;

        .title {
            font-weight: 700;
            text-align: center;
            font-size: 20px;
            // margin: 30px 0;
            position: relative;
            margin: 40px 0 30px 0;
        }

        .title::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -10px;
            width: 100%;
            height: 2px;
            background: #D35950;
        }

        .title::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -15px;
            width: 100%;
            height: 2px;
            background: #47B8D6;
        }

        .content {
            .subtitle {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                color: #fff;
                font-size: 16px;
                background: #1E91C9;
            }

            .info {
                border: 1px solid #2186B9;
                padding: 10px;

                .tableMod {
                    margin-bottom: 10px;

                    .table {
                        border: 1px dashed #ccc;
                        border-bottom: none;
                        margin: 5px 0;

                        .tr {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            justify-content: center;
                            padding: 10px;
                            border-bottom: 1px dashed #ccc;
                        }

                        .th {
                            display: flex;

                            .tr1 {
                                width: 30%;
                            }

                            .tr2 {
                                width: 70%;
                                border-left: 1px dashed #ccc;
                            }
                        }

                        .tabtd {
                            display: flex;

                            .left {
                                width: 30%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                .translate {
                                    width: 100%;
                                    text-align: start;
                                }
                            }

                            .right {
                                width: 70%;
                                display: flex;
                                border-left: 1px dashed #ccc;
                                align-items: flex-start;

                                .degree {
                                    height: 20px;
                                    width: 100%;
                                }
                            }
                        }
                    }

                }

                .contImg {
                    height: 100%;
                    // height: 700px;
                }
            }

            .explainTips {
                margin-top: 10px;
            }
        }

        .leptinTip {
            position: absolute;
            left: 0;
            bottom: 20px;
        }
    }
}
</style>
